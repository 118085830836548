import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const isArray = <T>(target: T | T[]): target is T[] => Array.isArray(target);

type State<T extends string | number | string[] | number[] | undefined> =
  | { loaded: false }
  | { loaded: true; valid: false }
  | { loaded: true; valid: true; value: T };

export const useResolveQueryValue = <T extends string | number | string[] | number[] | undefined>(
  queryKey: string,
  converter: (query: string | undefined) => T | null
): State<T> => {
  const router = useRouter();

  const [state, setState] = useState<State<T>>({ loaded: false });

  useEffect(() => {
    if (router.isReady === false) return;

    const queryStrVal: string | string[] | undefined = router.query[queryKey];
    if (isArray(queryStrVal))
      throw new Error(
        'useResolveQueryValue(...) does not support multiple queries such as `[...path].ts.`'
      );
    const value = converter(queryStrVal);
    if (value === null) return setState({ loaded: true, valid: false });
    return setState({ loaded: true, valid: true, value: value });
  }, [router]);

  return state;
};
