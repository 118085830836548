import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { textCss } from '~/styles/textCss';

export const outerContainer = css`
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: ${colors.gray7};
  padding: 0 16px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

export const container = css`
  max-width: 600px;
  width: 100%;
  padding: 48px 0;
`;

export const logoTitle = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    margin-bottom: 48px;
  `,
  title: css`
    ${textCss({ size: 'xl', weight: 'bold' })}
  `,
};
