import React from 'react';
import * as styles from './index.styles';

export type Props = {
  steps: {
    title: string;
    subTitle?: React.ReactNode;
    body: React.ReactNode;
  }[];
  activeStepIndex: number;
};

export const SectionFlowStepper = (props: Props) => {
  return (
    <div css={styles.container}>
      {props.steps.map((step, index) => {
        const active = props.activeStepIndex === index;
        return (
          <div css={styles.step.container} key={index}>
            <div css={styles.step.heading.container}>
              <div
                css={[
                  styles.step.heading.count.base,
                  active ? styles.step.heading.count.active : styles.step.heading.count.disable,
                ]}
              >
                {index + 1}
              </div>
              <div
                css={[
                  styles.step.heading.title.base,
                  active ? styles.step.heading.title.active : styles.step.heading.title.disable,
                ]}
              >
                {step.title}
              </div>
            </div>
            {active && (
              <div css={styles.step.content.base}>
                {step.subTitle && <div css={styles.step.heading.subTitle}>{step.subTitle}</div>}
                {step.body}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
