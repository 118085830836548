import { css } from '@emotion/react';
import { colors } from '~/styles/colors';
import { mediaQueries } from '~/styles/mediaQueries';
import { textCss } from '~/styles/textCss';

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const step = {
  container: css`
    display: flex;
    flex-direction: column;
    gap: 16px;
    line-height: 24px;
  `,
  heading: {
    container: css`
      display: flex;
      gap: 8px;
      align-items: center;
      border: none;
      background: none;
    `,
    count: {
      base: css`
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 12px;
        text-align: center;
        font-weight: bold;
      `,
      active: css`
        background-color: ${colors.accent};
        color: ${colors.white};
      `,
      disable: css`
        background-color: ${colors.gray4};
        color: ${colors.white};
      `,
    },
    title: {
      base: css`
        ${textCss({ size: 'm', weight: 'bold' })}
      `,
      active: css``,
      disable: css`
        color: ${colors.gray4};
      `,
    },
    subTitle: css`
      ${textCss({ size: 's', weight: 'regular' })}

      margin-bottom: 16px;
      line-height: 20px;
      white-space: pre-wrap;
    `,
  },
  content: {
    base: css`
      padding-left: 16px;
      margin-left: 11px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-left: 2px solid ${colors.gray5};

      ${mediaQueries.mobile} {
        padding-left: 12px;
      }
    `,
  },
};
