import React from 'react';
import * as styles from './index.styles';

import { applicationConfig } from '~/constants/applicationConfig';
import Image from 'next/image';

export type Props = {
  children: React.ReactNode;
};

export const RegisterLayout: React.FC<Props> = (props: Props) => {
  return (
    <div css={styles.outerContainer}>
      <div css={styles.container}>
        <div css={styles.logoTitle.container}>
          <Image width={256} height={56} src={'/assets/logo/with-icon.svg'} alt="タイミー" />
          <div css={styles.logoTitle.title}>{applicationConfig.serviceName}</div>
        </div>

        {props.children}
      </div>
    </div>
  );
};
