import React from 'react';
import * as styles from './index.styles';
import Circle from 'react-circle';
import { colors } from '~/styles/colors';
import Image from 'next/image';

export type Props = {
  steps: string[];
  currentStep: number;
};

export const Stepper: React.FC<Props> = (props: Props) => {
  const checkIcon = <Image src={'/assets/icon/check--accent.svg'} width={24} height={24} alt="" />;

  const stepStatus = (idx: number): 'COMPLETED' | 'ACTIVE' | 'NOT_YET' =>
    idx < props.currentStep ? 'COMPLETED' : idx === props.currentStep ? 'ACTIVE' : 'NOT_YET';

  return (
    <>
      <div css={styles.spContainer}>
        <div css={styles.progressCircleContainer}>
          <Circle
            progress={((props.currentStep + 1) / props.steps.length) * 100}
            roundedStroke
            animate
            size={'64'}
            showPercentageSymbol={false}
            showPercentage={false}
            progressColor={colors.accent}
          />
          <div css={styles.progressText}>
            {props.currentStep + 1}/{props.steps.length}
          </div>
        </div>
        {/* TDDO: 修正 */}
        <div>
          <div css={styles.currentStepText}>{props.steps[props.currentStep]}</div>
          {props.currentStep + 1 < props.steps.length && (
            <div css={styles.nextStepText}>NEXT: {props.steps[props.currentStep + 1]}</div>
          )}
        </div>
      </div>

      <ol css={styles.pcContainer}>
        {props.steps.map((stepName, idx) => (
          <li
            css={[
              styles.stepContainer.base,
              stepStatus(idx) === 'COMPLETED'
                ? styles.stepContainer.completed
                : stepStatus(idx) === 'ACTIVE'
                  ? styles.stepContainer.active
                  : null,
            ]}
            key={idx}
          >
            <div css={styles.stepTextContainer}>
              {stepStatus(idx) === 'COMPLETED' ? checkIcon : <div>{idx + 1}.</div>}
              <div css={styles.stepText}>{stepName}</div>
            </div>
            <div
              css={[
                styles.stepBar.base,
                (stepStatus(idx) === 'COMPLETED' || stepStatus(idx) === 'ACTIVE') &&
                  styles.stepBar.active,
              ]}
            />
          </li>
        ))}
      </ol>
    </>
  );
};
